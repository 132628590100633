import React from "react"
import {useIntl} from "gatsby-plugin-intl"

//Particles
import Particles from "react-particles-js"
import particles from "../assets/indexParticles.json"

//Components
import Layout from "../components/layout"
import SEO from "../components/seo"

//Styles
import indexStyles from "./index.module.scss"


const IndexPage = () => {
  const intl = useIntl()
  return (
    <Layout>
      <SEO title={intl.formatMessage({id: "index__title"})} />
      <Particles className={indexStyles.particles} params={particles} />
      <section className={indexStyles.upperHero}>
        <h1>
          {intl.formatMessage({id: "index__my_name"})}
        </h1> 
      </section>
      <section className={indexStyles.lowerHero}>
        <h4>{intl.formatMessage({id: "index__my_title"})}</h4> 
      </section>
    </Layout>
  )
}
export default IndexPage
